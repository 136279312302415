import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useForm } from 'react-hook-form'
import appFirebase from '../credentials'
import { getFirestore, addDoc, collection } from "firebase/firestore"
const db = getFirestore(appFirebase)

export default function FormContact() {
    const { register, reset, handleSubmit, formState: { errors, isSubmitSuccessful } } = useForm();
    const [dateHour, setDateHour] = useState(new Date());

    const onSubmit = async data => {
        let date = new Date()
        setDateHour(date)

        try {
            await addDoc(collection(db, "register"), data);
          } catch (e) {
            console.error("Error adding document: ", e);
          }

		if(!isSubmitSuccessful) {
           const spanAll = window.document.querySelectorAll("#btn_form span")
           spanAll[1].style.transform= "translateY(0)"
           
           setTimeout(()=> {
               spanAll[1].style.transform= "translateY(-110%)"
           }, 2000)
        }
	}
	
	useEffect(() => {
		reset({
			name: "",
			company: "",
			email: "",
			details: ""
		})
	}, [reset, isSubmitSuccessful])

    return (
        <section className="form-wrapper center dark" id="contact">
            <div className="form-content">
                <form className="form-contact" onSubmit={handleSubmit(onSubmit)} data-aos="fade-right" data-aos-duration="1000">
                    <h2 className='title-section'>Contact</h2>
                    <div className="inputstyle">
                        <input id='name' type="text" {...register('name', {
                            required: true
                        })} autoComplete="off" placeholder='Name'/>
                        {errors.name?.type === 'required' && <p className='warning'>The field Name is required</p>}
                    </div>
                    <div className="inputstyle">
                        <input id='company' type="text" {...register('company')} autoComplete="off" placeholder='Company'/>
                    </div>
                    <div className="inputstyle">
                        <input id='email' type="text" {...register('email', {
                            required: true,
                            //eslint-disable-next-line
                            pattern: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
                        })} autoComplete="off" placeholder='Email'/>
                        {errors.email?.type === 'required' && <p className='warning'>The field Email is required</p>}
                        {errors.email?.type === 'pattern' && <p className='warning'>The Email's format is wrong</p>}
                    </div>
                    <textarea id='details' className="details" {...register('details', {
						required: true
                    })} autoComplete="off" placeholder='Details'/>
                    {errors.details?.type === 'required' && <p className='warning'>The field Details is required</p>}
                    <input type="hidden" id="time" value={dateHour} {...register('time')}/>
                    <button type="submit" className="btn red" id="btn_form">
                        <span>SEND</span>
                        <span><FontAwesomeIcon icon={faCheck}/></span>
                    </button>
                </form>
                <div className="map-content" data-aos="fade-left" data-aos-duration="1000">
                    <div className="map-small">
                        <div className="point"></div>
                        <img src="https://i.imgur.com/4wkdeR8.png" alt=""/>
                        <div className="info-map">
                                <p>Guarenas, Edo. Miranda</p>
                        </div>
                    </div>
                    <img className="map" src="https://i.imgur.com/7kHOFv9.png" alt=""/>
                    <img className="ubication-icon" src="https://i.imgur.com/fLfsHDA.png" alt=""/>
                </div>
            </div>
        </section>
    );
}