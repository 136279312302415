import React from 'react';

export default function AboutMe() {

    return (
        <div className='about-wrapper center dark' id="about">
            <div className="about-content">
                <div className="about container" data-aos="fade-up" data-aos-duration="1000">
                    <h2 className='title-section'>About Me</h2>
                    <span className="bar"></span>
                    <p>
                        Over time I have managed to discover that programming is my passion because when I'm learning new things I get excited. Everything in our lives is a system and being a part of compiling the ideas of others is rewarding. In my spare time I enjoy gaming, listening to music and building my own projects. My preferred tools javascript-based.
                    </p>
                </div>
                <div className="about-img" data-aos="fade-up" data-aos-duration="1000">
                    <div className="bg-img">
                        <img className="photo" src="https://i.imgur.com/0jwY3Vs.jpg" alt="Reyker Escalona"/>
                    </div>
                </div>
            </div>
        </div>
    );
}