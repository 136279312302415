import ReactDOM from 'react-dom';

const portalRoot = document.getElementById('menu-portal');

export default function ModalMenu({ handleCloseModal, modal }) {
    let style = {
        top: '',
        opacity: ''
    }

    if(modal) {
        style.top = '0';
        style.opacity = '1'
    }

    else {
        style.top = '-100em';
        style.opacity = '0.4'
    }

    return (
        ReactDOM.createPortal(
            <div className="modal" style={style}>
                <h2>Web</h2>
                <div className="modal-container">
                    <div className="navbar-menu">
                        <a href="#about" data-text={"About"} onClick={handleCloseModal}>About</a>
                        <a href="#skills" data-text={"skills"} onClick={handleCloseModal}>Skills</a>
                        <a href="#contact" data-text={"contact"} onClick={handleCloseModal}>Contact</a>
                    </div>
                </div>
            </div>,
        portalRoot)
    );
}