import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import ModalPortal from './ModalMenu';

export default function Navbar() {
    const [modal, setModal] = useState(false);
    const [OffSetY, setOffSetY] = useState(0);

    const handleOpenModal = () => {
        setModal(true);
        setOffSetY(window.scrollY);
       
    };

    const handleCloseModal = () => {
        setModal(false);
        window.scrollTo(0, OffSetY);
    };

    return (
        <div className="navbar-wrapper">
            {/* <img className="navbar-logo" src="logo2.png" alt="logo"/> */}
            <ModalPortal handleCloseModal={handleCloseModal} modal={modal}/>
            {
                modal ? 
                <FontAwesomeIcon icon={faTimes} className="icon-x" onClick={handleCloseModal}/>
                : 
                <FontAwesomeIcon icon={faBars} className="icon-bars" onClick={handleOpenModal}/>
            }
        </div>
    );
}