import React from 'react';

export default function Footer() {

    return (
        <div className="footer-wrapper light">
            <div className="footer-content">
                <h2 className='title-section'>What any project in your mind ?</h2>
                <h3>If you think I’d be a good match contact me !</h3>
            </div>
            <div className="footer" data-aos="fade-up" data-aos-duration="1000">
                <h4>Website was made with:</h4>
                <div>
                    <img src="https://i.imgur.com/EWCgycK.png" alt="" />
                    <img src="https://i.imgur.com/CqckP5o.png" alt="" />
                    <img src="https://i.imgur.com/ZSlQF5A.png" alt="" />
                    <img src="https://i.imgur.com/odaQeb8.png" alt="" />
                    <img src="https://i.imgur.com/SUoFouZ.png" alt="" />
                    <img src="https://i.imgur.com/Xj72rGE.png" alt="" />
                </div>
            </div>
        </div >
    );
}