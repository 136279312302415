import React, { useState } from 'react';

export default function Banner() {
	const [ flags, setFlags ] = useState(false)

	const handleCvOpen = () => {
		setFlags(true)
	}

	const handleCvClose = () => {
		setFlags(false)
	}

    return (
        <section className='banner-wrapper'>
            <div className='bg-video'>
                <video autoPlay src="bgvideo.webm" loop muted>
                </video>
                <span className="bgvideo-overlay"></span>
            </div>
            <div className="banner-content">
                <h1>
                    <span>H</span>
                    <span>i</span>
                    <span>,</span>
                    <br/>
                    <span>I</span>
                    <span>'</span>
                    <span>m&nbsp;</span>
                    <span>R</span>
                    <span>e</span>
                    <span>y</span>
                    <span>k</span>
                    <span>e</span>
                    <span>r</span>
                    <br/>
                    <span>W</span>
                    <span>e</span>
                    <span>b&nbsp;</span>
                    <span>d</span>
                    <span>e</span>
                    <span>v</span>
                    <span>e</span>
                    <span>l</span>
                    <span>o</span>
                    <span>p</span>
                    <span>e</span>
                    <span>r</span>
                </h1>
				{flags ? 
					<div className='idiom'>
						<a href="reykerES.pdf" download="Reyker Escalona.pdf" onClick={handleCvClose}>
						</a>
						<a href="reykerEN.pdf" download="Reyker Escalona.pdf" onClick={handleCvClose}>
						</a>
					</div>
				:
					<button className="btn red" onClick={handleCvOpen}>
						<span>DOWNLOAD CV</span>
					</button>
				}
            </div>
        </section>
    );
}