import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './sass/display.scss';
import Navbar from './components/Navbar';
import Banner from './components/Banner';
import AboutMe from './components/AboutMe';
import Skills from './components/Skills';
import FormContact from './components/FormContact';
import Footer from './components/Footer';
import SocialMedias from './components/SocialMedias';
import ScrollToTop from './components/ScrollToTop';
// import Projects from './components/Projects'

export default function App() {
	AOS.init();

	return (
		<div className="app">
			<Navbar/>
			<SocialMedias/>
			<ScrollToTop/>
			<div className="sections">
				<Banner/>
				<AboutMe/>
				<Skills/>
				{/* <Projects/> */}
				<FormContact/>
				<Footer/>
			</div>
		</div>
	);
}