import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from "react";

export default function ScrollToTop() {
    const [scrollY, setScrollY] = useState(0);

    const Scrolling = () => {
        setScrollY(window.scrollY);
    }

	const goToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}
    
    useEffect(() => {
        window.addEventListener("scroll", Scrolling);

        return () => window.removeEventListener("scroll", Scrolling);
    });

    return(
		<>
			{scrollY > 400 && 
				<div className="button-top center" onClick={goToTop}>
				<FontAwesomeIcon className="arrow-up" icon={faArrowUp}/>
			</div>}
		</>
    );
}