import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

const SocialNet = (props) => {
    return(
        <div className="social">
            <a href={props.url} target={"_blank"} rel="noopener noreferrer">
                <div className="center">
                    <FontAwesomeIcon icon={props.icon} className="icon-social"/>
                </div>
            </a>
            <div className="stick-box">
                <div className="stick"></div>
            </div>
        </div>
    );
}

export default function SocialMedias() {
    return (
        <div className="social-wrapper">
            <div className="social-content">
                <SocialNet url={"https://www.facebook.com/reyker.gonzalez/"} icon={faFacebook}/>
                <SocialNet url={"https://www.instagram.com/reykergonzalez/"} icon={faInstagram}/>
                {/* <SocialNet url={"https://www.instagram.com/reykergonzalez/"} icon={faLinkedin}/> */}
            </div>
        </div>
    );
}