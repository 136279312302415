// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDUbY8A4at6JNQBgkSLH1aKnqOJ3uUaQMY",
  authDomain: "portfolio-ceef2.firebaseapp.com",
  projectId: "portfolio-ceef2",
  storageBucket: "portfolio-ceef2.appspot.com",
  messagingSenderId: "326454102972",
  appId: "1:326454102972:web:68f7bcff69e9653e7cf9b9"
};

// Initialize Firebase
const appFirebase = initializeApp(firebaseConfig);

export default appFirebase