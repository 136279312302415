import React, { useEffect, useRef, useState } from 'react';

export default function Skills() {
    const skill = useRef(null);
    const [scrollY, setScrollY] = useState(0);
    let percentsSkill = {
        html: "80",
        css: "80",
        javascript: "60",
        git: "40",
        react: "25",
        sql: "40"
    };
    
    const handleScroll = () => {
        setScrollY(window.scrollY);
    }

    useEffect(() => {
        if(scrollY >= skill.current.offsetTop -200) {
            for(let property in percentsSkill) {
                let element = document.querySelector(`#skill_${property} .progress-bar`);
                element.style.width= `${percentsSkill[property]}%`;
            }
        }

        window.addEventListener("scroll", handleScroll);
        

        return () => window.removeEventListener("scroll", handleScroll);
    });
    
    return (
        <div className="skills-wrapper center light" id='skills' ref={skill}>
            <div className="skills-content">
                <h2 className='title-section'>Skills</h2>
                <div className="bar"></div>
                <div className="skills" data-aos="fade-up" data-aos-duration="500">
                    <AllSkills percent={percentsSkill.html} name={"html"} src={"https://i.imgur.com/EWCgycK.png"}/>
                    <AllSkills percent={percentsSkill.css} name={"css"} src={"https://i.imgur.com/CqckP5o.png"}/>
                    <AllSkills percent={percentsSkill.javascript} name={"javascript"} src={"https://i.imgur.com/ZSlQF5A.png"}/>
                    <AllSkills percent={percentsSkill.git} name={"git"} src={"https://i.imgur.com/oENcRph.png"}/>
                    <AllSkills percent={percentsSkill.react} name={"react"} src={"https://i.imgur.com/odaQeb8.png"}/>
                    <AllSkills percent={percentsSkill.sql} name={"sql"} src={"https://i.imgur.com/hobOTqR.png"}/>
                </div>
            </div>
        </div>
    );
}

const AllSkills = (props) => {

    return(
        <div className="skill">
            <img src={props.src} alt="" className="skill-img" />
            <div className="skill-info">
                <div className="skill-details">
                    <div className="skill-name">{props.name}</div>
                    <div className="skill-percent">
                        <span className='percent'>{props.percent}</span>
                        <span>%</span>
                    </div>
                </div>
                <div className="skill-bar">
                    <div className="skill-progress-bar" id={"skill_" + props.name}>
                        <div className="progress-bar"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}